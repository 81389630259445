import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const ASFProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Alexander Street Furniture.<br />
              Inspired locally.<br />
              Manufactured in China.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci is proud to support Alexander Street Furniture by bringing amazing pieces of contemporary & classic French furniture to life exclusively on their behalf.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Alexander Street Furniture (ASF) is a Sydney based high-end furniture brand that was founded by a busy mum who had a passion for gorgeous furniture and for bringing quality and style at an affordable budget. Prior to approaching Sourci ASF were experiencing product quality issues, inflated purchase prices, and regular pressure to increase order spend and volumes.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Within months, we were able to reduce their per unit cost, improve product quality, and keep their minimum order quantities down to a manageable level – all whilst giving ASF owner Kylie her time back. With 4 children under the age of 5, her time is extremely precious, so by bringing cost savings and giving back 90% of her supply chain time, the value Sourci brought to this small business compounds tremendously!
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/alexander-street-furniture-1.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>15%</span>
            </p>
            <p className="font-black text-xl">NET COST SAVINGS</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>5,000+</span>
            </p>
            <p className="font-black text-xl">TOTAL SPEND REDUCTION</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>20</span>
            </p>
            <p className="font-black text-xl">HRS GAINED PER ORDER</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>100's</span>
            </p>
            <p className="font-black text-xl">CUSTOMISED FURNITURE PIECES</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/alexander-street-furniture-2-1875w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              How we made amazing happen
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Having found their original supplier on Alibaba.com, ASF were experiencing supply chain issues that held their brand back from growing. As we suspected, they were actually ordering from a Trading Company rather than a true manufacturer.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              By purchasing from a Trading Company a business has very little control over their supply chain. Trading companies buy and re-sell goods at inflated prices, and can very often switch suppliers on you, so that your quality and consistency is extremely volatile. The trading company that ASF were buying from were poorly organised, had no QC processes, and would force their customers to order a full container load (FCL) at a time, meaning ASF had to always stretch and increase their order spend – suffocating their cashflow.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Enter Sourci in 2019, and we completely revamped their supply chain. Within a month we presented highly qualified furniture manufacturers, in fact we found the original factories that manufactured the items that the trading company was (poorly) replicating.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team.
            <span className="block text-sourci-pink">Push the button. Make amazing happen.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <p className="text-xl lg:text-2xl mb-8">
              Not only did we solve the quality issues, our factories were able to customise absolutely every colour, fabric, timber finish, stain and truly bring bespoke classic furniture pieces to life.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              On top of that, without the trading company margins, ASF experienced a NET cost saving on their furniture manufacturing! Including any Sourci fees, a true cost saving on each order. Directly correlating to an increase in profitability.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Further still, we weren’t bound to ordering full container loads at a time, meaning cashflow wasn’t being strangled, and ASF could push more funds into marketing, staging, photography, and properly growing their brand!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Fast forward to today, Alexander Street Furniture have now expanded their operations, moved to a larger warehouse, and grown a substantial customer base in the high-end furniture market. Their supply operations run as smooth as possible, and the team have time and energy to work on the business rather than in the business.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              That’s a ‘home-run’ by Sourci’s judgement!
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/alexander-street-furniture-3-1875w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - Alexander Street Furniture" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="alexander-street-furniture"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default ASFProject;

export function Head() {
  return (
    <Seo title="Alexander Street Furniture | Our Projects | Sourci"
      description="Prior to approaching Sourci Alexander Street Furniture were experiencing product quality issues, inflated purchase prices, and regular pressure to increase order spend and volumes."
      canonical="/projects/alexander-street-furniture" />
  )
}